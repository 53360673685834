@import url("https://fonts.googleapis.com/css?family=Lato:400,700");

.homepage-container {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  padding: 0rem !important;
  box-sizing: border-box;
}

.homepage-banner {
  height: 45vh;
  background: linear-gradient(
    180deg,
    #8bceed 26.58%,
    rgba(139, 206, 237, 0.21) 100%
  );
  background: url("../../assets/homepage_banner_graphic.png"),
    linear-gradient(180deg, #e1f8ff 0%, #fdffff 100%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.homepage-banner-title {
  font-family: Lato;
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 4rem;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  width: 100%;
  margin: 3rem 0 1rem 0;
}

.homepage-banner-button-container {
  display: flex;
  flex-direction: row;
  width: 27rem;
  align-items: center;
  justify-content: space-between;
}

.homepage-banner-button {
  background: rgba(0, 191, 246, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 0.8rem;
  width: 12rem;
  height: 2.5rem;
  font-family: Lato;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: center;
  transition: transform 300ms, background 400ms;
}

.homepage-banner-button:hover {
  transform: scale(1.15);
  background: rgb(1, 155, 197);
}

.homepage-body {
  padding-bottom: 5vh;
}

.homepage-body-listing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 2rem 1rem 4rem;
}

.homepage-body-title {
  font-family: Lato;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: center;
  color: black;
  padding: 0 0 1.5rem 0;
}

.homepage-body-listings {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 2rem;
  width: 100%;
}

.homepage-body-listing-item {
  transition: transform 300ms;
  width: fit-content;
}

.homepage-body-listing-item:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 1060px) {
  .homepage-banner {
    height: 35vh;
  }

  .homepage-banner-title {
    font-size: 2rem;
    line-height: 3rem;
    margin: 2rem 0 0.25rem 0;
  }

  .homepage-banner-button-container {
    width: 22rem;
  }

  .homepage-banner-button {
    border-radius: 0.65rem;
    width: 10rem;
    height: 2.25rem;
    font-size: 1rem;
  }

  .homepage-body-title {
    font-size: 1.35rem;
    line-height: 2rem;
    padding: 0 0 1.5rem 0;
  }

  .homepage-body-listings {
    row-gap: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .homepage-banner {
    height: 30vh;
    padding-top: 2vh;
  }

  .homepage-banner-title {
    font-size: 1.75rem;
    line-height: 2rem;
    margin: 0.75rem;
    width: 50%;
  }

  .homepage-banner-button-container {
    width: 15rem;
  }

  .homepage-banner-button {
    border-radius: 0.5rem;
    width: 7rem;
    height: 1.8rem;
    font-size: 0.8rem;
    line-height: 1rem;
  }

  .homepage-body-listing-container {
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  }

  .homepage-body-title {
    font-size: 1rem;
    line-height: 2rem;
    padding: 0 0 1.5rem 0;
  }

  .homepage-body-listings {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
  }
}

/* Responsive for phones */
@media screen and (max-device-width: 600px) {
  .homepage-banner {
    height: 40vh;
    background: url("../../assets/homepage_banner_graphic_small.png"),
      linear-gradient(180deg, #e1f8ff 0%, #fdffff 100%);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
  }

  .homepage-banner-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 1.5rem;
    width: 100%;
  }

  .homepage-banner-button-container {
    width: 15rem;
  }

  .homepage-banner-button {
    border-radius: 0.5rem;
    width: 7rem;
    height: 1.75rem;
    font-size: 0.75rem;
  }

  .homepage-body-listing-container {
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  }

  .homepage-body-title {
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 0 0 1.5rem 0;
  }

  .homepage-body-listings {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
}

/* OLD HOMEPAGE STUFF BELOW */

.homepage-MAIN-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  justify-content: flex-end; /* Adjust justify-content */
  background: linear-gradient(180deg, #8bceed 32.81%, #ffffff 100%);
  min-height: 100vh;
}

.homepage-boxtop {
  box-sizing: border-box;
  position: absolute;
  width: 100%; /* Set the width to 100% to span across the entire top */
  height: 126px;
  left: 0;
  top: 0;
  background: #ffffff;
}

.homepage-content {
  /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 10px;
}

.homepage-bigbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 20px;
}

.homepage-white-container {
  width: 43%;
  height: 190%;
  flex-shrink: 0;
  border-radius: 30px;
  background: #fefefe;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: -490px auto;
  text-align: center; /*center inline*/
  padding: 20px;
}

.homepage-header {
  box-sizing: border-box;
  margin-left: 200px;
  width: 100%;
  height: 126px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 76px;
  text-align: center;
  color: #000000;
  margin-top: 40px; /* Add margin-top to create space between the white container and the header */
}

.homepage-button1 {
  /* List With Us */

  /* position: absolute;
width: 140px;
height: 32.05px;
left: 1202px;
top: 55px; */
  width: 240px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-left: 5px; /* Add margin to create spacing between the buttons */
  border: none; /* Remove border */
  background: #ffffff; /* Set background color to match the container */
  color: #00bff6;
}

.homepage-button2 {
  /* Sign In */
  /* position: absolute;
width: 79px;
height: 32.05px;
left: 1369px;
top: 55.26px; */

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  border: none;
  margin-left: 15px;
  margin-right: 5px; /* Add margin to create spacing between the buttons */
  background: #ffffff; /* Set background color to match the container */

  color: #00bff6;
}
.homepage-button3 {
  /* position: absolute;
  width: 403px;
  height: 72px;
  left: 555px;
  top: 520px;
  border: none;

  background: #00BFF6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;  */

  position: absolute;
  width: 403px;
  height: 72px;
  left: calc(50% - 201.5px); /* Center the button horizontally */
  top: 550px;
  border: none;
  background: #00bff6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homepage-button3text {
  /* box-sizing: border-box;

  position: absolute;
  width: 424px;
  height: 46px;
  left: 544px;
  top: 514px;
  
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  
  color: #FFFFFF;   */

  box-sizing: border-box;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}
.homepage-logo {
  position: absolute;
  width: 60px;
  height: auto;
  left: 20px;
  top: 20px;
}

.homepage-title {
  box-sizing: border-box;
  position: absolute;
  width: 934px;
  height: 223px;
  left: calc(50% - 451.5px); /* Center the button horizontally */
  top: 237px;

  /* display: flex;
    align-items: center;
    justify-content: center; */

  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 75px;
  line-height: 90px;
  text-align: center;
  margin: -20px auto;

  color: #ffffff;
}

.homepage-boxbot {
  box-sizing: border-box;
  position: absolute;

  width: 100%; /* Set the width to 100% to span across the entire top */
  height: 400px;
  /* left: 35px; */
  bottom: 0;
  top: 900px; /*Change the gradient blue box*/
  background: #ffffff;
  /* margin-right: 20px; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.homepage-populartext {
  box-sizing: border-box;
  position: absolute;
  width: 570px;
  height: 75px;
  /* left: 1%; Adjust the left spacing as needed */
  margin-left: 30px;
  top: -10%;

  transform: translateY(-50%); /* Center the text vertically */
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #00bff6;
}

/* .homepage-image-container {
    display: flex;
    justify-content: flex-start; 
    align-items: flex-start;
    margin-top: 100px;


  } */

.address-block {
  display: flex;
  flex-wrap: wrap;
  /* margin-left: 3%; */
  /* justify-content: space-between; */
}
/* .address-block > div {
    flex: 1 0 24%; 
    margin: 0 1%;
  }
  
  @media (max-width: 768px) {
    .address-block > div {
      flex: 1 0 48%;
    } 
  }
  
  @media (max-width: 600px) {
    .address-block > div {
      flex: 1 0 100%;
    }
  } */

.homepage-image {
  border-radius: 10px;
  width: 415px;
  height: 246px;

  /* margin-left: 10px; */
  margin-right: 30px;
}

.homepage-image-detail1 {
  margin-top: 10px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;

  line-height: 30px;
  color: #000000;
}

.homepage-image-detail2 {
  font-family: "Lato";
  font-style: italic;
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  color: #9d9d9d;
}

.circle-image {
  position: absolute;
  top: 370px;
  width: 60px;
  height: 60px;
  left: 420px;
}

/* white container CSS */
.homepage-rangeline {
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Add spacing between this line and other content */
  justify-content: center;
}

.homepage-rangeline p,
.homepage-rangeline button {
  margin: 0 10px; /* Add spacing between elements */
  font-family: "Lato";
  font-weight: 600;
  font-size: 25px;
  color: #000;
}

/* Style for the "to" text */
.homepage-rangeline .homepage-to {
  display: inline-block; /* Add inline-block display to the "to" element */
  font-family: Inter;
  font-size: 25px;
  font-weight: 600;
  color: #00bff6;
  margin: 0 10px; /* Add spacing between "to" and buttons */
}

/* Move the entire row down */
.homepage-rangeline {
  transform: translateY(40px); /* Adjust the value to move the row down */
}

.homepage-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px; /* Adjust the spacing */
}

.homepage-button-search {
  width: 197px;
  height: 46px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 19px;
  background: #00bff6;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
}

.homepage-button-explore {
  color: #00bff6;
  width: 190px;
  font-size: 16px;
  text-decoration-line: underline;
  margin-top: 20px; /* Adjust the spacing */
}

/* Button container */
.homepage-button {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="112" height="64" viewBox="0 0 112 64" fill="none"><g filter="url(%23filter0_d_414_1971)"><rect x="5" y="1" width="102" height="54" rx="19" stroke="%2300BFF6" stroke-width="2" shape-rendering="crispEdges"/></g><defs><filter id="filter0_d_414_1971" x="0" y="0" width="112" height="64" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_414_1971"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_414_1971" result="shape"/></filter></defs></svg>');
  display: flex;
  align-items: center;
  margin: 10px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
}

/* SVG background */
.button-outer {
  width: 104px;
  height: 56px;
  flex-shrink: 0;
  background-size: 100% 100%;
}

/* Text */
.button-text {
  width: 57px;
  height: 30px;
  flex-shrink: 0;
  color: #d9d9d9;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Button container */
.homepage-button {
  display: flex;
  align-items: center;
  margin: 10px; /* Adjust as needed */
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative; /* Add this */
}

/* Outer container */
.button-outer {
  width: 104px;
  height: 56px;
  flex-shrink: 0;
  position: absolute; /* Position absolutely */
  top: 0;
  left: 0;
}

/* SVG background */
.button-outer svg {
  width: 100%;
  height: 100%;
}

/* Button text */
.button-text {
  position: absolute; /* Position absolutely */
  top: 50%; /* Vertically center the text */
  left: 50%; /* Horizontally center the text */
  transform: translate(-50%, -50%); /* Center the text perfectly */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #d9d9d9;
  z-index: 1; /* Place the text above the SVG */
  background-color: rgba(0, 0, 0, 0.5); /* Example background color */
  padding: 5px 10px; /* Example padding */
  border-radius: 5px; /* Example border radius */
}
