.header-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  width: 100vw;
}

.header-title {
  width: 100%;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 1.35rem;
  text-align: center;
  color: #000000;
  justify-self: center;
}

.header-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
  width: 20%;
  height: 100%;
  padding: 0 1.5rem 0 0;
  white-space: nowrap;
}

.header-dropdown {
  position: absolute;
  top: 3rem;
  right: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(255, 255, 255, 1);
  width: 9rem;
  height: 9rem;
  padding: 1rem 0.5rem 1rem 0.5rem;
  border-radius: 1.2rem;
  z-index: 1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  transition: visibility 500ms;
}

.header-button {
  border: none; /* Remove border */
  background: none;
  color: #00bff6;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2rem;
  transition: color 250ms;
}

.header-button:hover {
  color: rgb(0, 120, 152);
}

.header-logo-container {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-logo {
  width: 2rem;
  height: auto;
  left: 20px;
  top: 20px;
}

@media screen and (max-width: 600px) {
  .header-container {
    padding: 0.5rem;
  }

  .header-title {
    font-size: 0.85rem;
  }

  .header-dropdown {
    right: 0.25rem;
  }

  .header-links {
    width: 30%;
    gap: 0.5rem;
    padding: 0 0.25rem 0 0;
  }

  .header-logo-container {
    width: 30%;
  }

  .header-logo {
    width: 1.75rem;
  }
}
