.footer-container {
  position: absolute;
  bottom: 0;
  height: 5vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.25rem 1rem;
}
