.sidebar {
  position: fixed;
  top: 10;
  left: 0;
  width: 12.5rem;

  flex-shrink: 0;

  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  margin-left: 20px;

  z-index: 1; /* Make sure the sidebar appears above other content */

  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
}

.sidebar h2 {
  color: #00bff6;
  font-family: Lato;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.sidebar-content {
  max-width: 100%; /* Prevent content from expanding the sidebar */
}

@media only screen and (max-width: 1100px) {
  .sidebar {
    gap: 0.5rem;
    padding: 0.75rem 0;
    margin-left: 10px;
    width: 8rem;
  }

  .sidebar h2 {
    font-size: 0.75rem;
  }
}
