.map-page-price-filter {
    width: 100%;
    margin-left: 35%; /* Adjust the margin-left value as needed */
    border-radius: 1rem;
    border: 2px solid #89d2f3;
    padding: 0.5rem;
    background: #fff;
    position: relative; /* Make the button position relative */
}

.input-price {
    width: 80%;
    padding: 0.5rem;
    border: 2px solid #89d2f3;
    border-radius: 8px;
    margin-bottom: 10px;
}


.search-button {
    background-color: #5cb3fd;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
}

.search-button:hover {
    background-color: #4aa1f3;
}

.dropdown-price {
    position: absolute;
    left: 44.5%; /* Adjust the left property as needed */
    transform: translateX(-50%); /* Center the dropdown under the button */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: rgba(255, 255, 255, 1);
    width: 9rem;
    height: 9rem;
    padding: 1rem 0.5rem 1rem 0.5rem;
    border-radius: 1.2rem;
    z-index: 1;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    transition: visibility 500ms;
}