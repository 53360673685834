@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

body, html {
  margin: 0;
  padding: 0;
}

.waitlist-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: linear-gradient(180deg, #8BCEED 32.81%, #FFFFFF 100%);
}

.waitlist-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
}

.waitlist-header {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  color: #FFFFFF;
  margin-top: -170px; /* Adjust the distance from the top */
}

.waitlist-logo {
  position: absolute;
  width: 770px;
  height: 754px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0.2;
}

.waitlist-title {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 600;
  font-size: 80px;
  line-height: 96px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 10px; /* Adjust the distance from the top */
}

.email-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.waitlist-email {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  margin-right: 10px;
}

.waitlist-waitlist {
  background: #00BFF6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  border: none;
  margin-top: 10px;
  padding: 10px 30px;
  cursor: pointer;
}

.waitlist-instagram1 {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 50px;
  top: 700px; /* Adjust the distance from the top */
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.waitlist-instagram2 {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 57px;
  top: 703px; /* Adjust the distance from the top */
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.waitlist-bottom {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #00BFF6;
  margin-top: 20px;
}

.waitlist-box {
  width: 561px;
  height: 46px;
  background: #FFFFFF;
  border-radius: 20px;
  border: none;
  margin-top: 20px;
}

.waitlist-input {
  width: 561px;
  height: 46px;
  border: none;
  background: #FFFFFF;
  border-radius: 20px;
  z-index: 1;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  padding-left: 18px;
  color: #333333;
}

.waitlist-input::placeholder {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #D9D9D9;
}

.thankyou-message {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 20px;
}

.waitlist-subheading {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 20px;
}

/* Buttons container */
.waitlist-buttons {
  display: flex;
  gap: 70px;
  margin-top: 20px;
}

/* List Your Apartment button */
.waitlist-list {
  flex: 1;
  background: #00BFF6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

/* Find An Apartment button */
.waitlist-find {
  flex: 1;
  background: #00BFF6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.waitlist-error {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  text-align: center;
  color: #de0000;
  margin-top: 20px;
}

.waitlist-bottom{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #00BFF6;
  margin-top: 80px;
}