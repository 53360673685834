/* styles.css */

.map-page-container {
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
}

.map-page-search {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.map-page-search-bar {
  width: 28.5%;
  border-radius: 1rem;
  border: 2px solid #89d2f3;
  padding: 0.5rem;
}

.map-page-price-filter {
  width: 6%;
  margin-left: 2%;
  border-radius: 1rem;
  border: 2px solid #89d2f3;
  padding: 0.5rem;
  background: #fff;
}

.map-page-filters-filter {
  width: 7%;
  margin-left: 2%;
  border-radius: 1rem;
  border: 2px solid #89d2f3;
  padding: 0.5rem;
  background: #fff;
}

.map-page-price-filter {
  width: 6%;
  margin-left: 2%;
  border-radius: 1rem;
  border: 2px solid #89d2f3;
  padding: 0.5rem;
  background: #fff;
}

.map-page-filters-filter {
  width: 7%;
  margin-left: 2%;
  border-radius: 1rem;
  border: 2px solid #89d2f3;
  padding: 0.5rem;
  background: #fff;
}

.map-page-listings {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 0 1rem;
  height: 82vh;
}

.map-container {
  margin-top: 10px;
  width: 65%;
}

.address-list-container {
  height: 100%;
  width: 35%; /* Width of the scrolling window */
  box-sizing: border-box; /* Include padding and border in width calculation */
  position: relative;
  bottom: 3.5rem;
}

.address-list {
  display: flex; /* Add this line to use flexbox */
  flex-direction: column; /* Stack address blocks vertically */
  align-items: center; /* Center address blocks horizontally */
  gap: 1rem;
}

.address-list-item {
  transition: filter 250ms;
  width: 100%;
}

.address-list-item:hover {
  filter: brightness(66%);
}

.address-list-load-more {
  background: rgba(0, 191, 246, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 0.8rem;
  width: 10rem;
  height: 2.5rem;
  font-family: Lato;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.8rem;
  letter-spacing: 0em;
  text-align: center;
  transition: transform 300ms, background 400ms;
}

.address-list-load-more:hover {
  transform: scale(1.15);
  background: rgb(1, 155, 197);
}

@media screen and (max-width: 1200px) {
  .map-page-search-bar {
    width: 34%;
  }

  .map-container {
    width: 60%;
  }

  .address-list-container {
    width: 40%; /* Width of the scrolling window */
  }
}

@media screen and (max-width: 1000px) {
  .map-page-search-bar {
    width: 27%;
  }

  .map-container {
    width: 50;
  }

  .address-list-container {
    width: 50%; /* Width of the scrolling window */
  }
}

@media screen and (max-width: 600px) {
  .map-page-search {
    justify-content: center;
  }

  .map-page-search-bar {
    width: 25%;
  }

  .map-container {
    display: none;
  }

  .address-list-container {
    margin-top: 0;
    margin-right: 0;
    padding: 0;
    width: 100%; /* Width of the scrolling window */
    bottom: 0;
  }

  .address-list {
    display: flex; /* Add this line to use flexbox */
    flex-direction: column; /* Stack address blocks vertically */
    gap: 2rem;
  }
}

.dropdown-bed {
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(255, 255, 255, 1);
  width: 9rem;
  height: 9rem;
  padding: 1rem 0.5rem 1rem 0.5rem;
  border-radius: 1.2rem;
  z-index: 1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  transition: visibility 500ms;
}
