/* BoxTemplate.css */
.resizablebox-template {
    height:auto;
    display: flex;
    padding: 2rem;
    overflow: auto; /* Add scrollbars when content overflows */
    border-radius: 2rem;
    border: 0.1rem solid rgba(175, 175, 175, 0.50);
    background: #FFF;
    margin-bottom:2rem;
    justify-content: center;
    align-items: center;
    margin-bottom:2rem;
}

