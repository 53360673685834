/* Add your other styles here */
.listing-page-container {
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
}

.sidebar-container {
  height: 100%;
  width: 15%;
  float: left;
}

.listing-page-body {
  flex-grow: 1;
  padding: 2rem 6rem 2rem 0;
  background-color: #ffffff;
  width: 80%;
  position: relative;
  float: right;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.listing-page-content-title {
  color: rgba(16, 15, 13, 1);
  font-family: "Lato";
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
}

/* Basic Details Section */

.listing-page-basic-details {
  border: 1px solid rgba(175, 175, 175, 0.5);
  border-radius: 1.5rem;
  padding: 3rem 6rem 3rem 6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.listing-page-basic-details-img {
  max-width: "100%";
  max-height: "100%";
  object-fit: "contain";
}

.basic-details {
  color: #00bff6;
  text-align: center;
  font-family: Lato;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 1rem;
  width: 60%;
}

.address {
  color: #000;
  font-family: Lato;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.address-text {
  width: 100%;
  height: 2.5rem;
  flex-shrink: 0;
  border-radius: 1rem;
  border: 3px solid #89d2f3;
  padding: 0.75rem;
}

.property-text {
  vertical-align: top;
  width: 100%;
  min-height: 6.5rem; /* Set a minimum height for the box */
  overflow-y: auto;
  flex-shrink: 0;
  fill: #fff;
  stroke-width: 3px;
  stroke: #89d2f3;
  border-radius: 15px;
  border: 3px solid #89d2f3;
  background: #fff;
  padding: 0.75rem;
}

/* Unit Details Section */
.listing-page-unit-details {
  border: 1px solid rgba(175, 175, 175, 0.5);
  border-radius: 1.5rem;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.unit-details {
  text-align: center;
  color: #00bff6;
  font-family: Lato;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.unit-details-selectors {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  column-gap: 2rem;
  width: 100%;
}

.unit-details-category {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
}

.unit-details-range-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* Unit Videos Section */
.listing-page-unit-videos {
  border: 1px solid rgba(175, 175, 175, 0.5);
  border-radius: 1.5rem;
  padding: 2rem 1rem 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.upload-button {
  background: rgba(0, 191, 246, 1);
  border: none;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  width: 12rem;
  height: 2.5rem;
  border-radius: 1rem;
  flex-shrink: 0;
  transition: transform 300ms, background 400ms;
}

.upload-button:hover {
  transform: scale(1.15);
  background: rgb(1, 155, 197);
}

.add-attachment-text {
  color: #9d9d9d;
  text-align: center;
  font-family: Lato;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.video-bar {
  width: 80%;
  height: 2.5rem;
  flex-shrink: 0;
  border-radius: 1rem;
  border: 3px solid #89d2f3;
  fill: #fff;
  stroke-width: 3px;
  stroke: #89d2f3;
  padding: 0.75rem;
}

/* Unit Photos Section */
.listing-page-unit-photos {
  border: 1px solid rgba(175, 175, 175, 0.5);
  border-radius: 1.5rem;
  padding: 2rem 1rem 2rem 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 1.5rem;
  align-items: center;
  justify-items: center;
}

.listing-page-unit-photos-cover {
  grid-column: 2;
}

.unit-photos-title {
  grid-column: 1 / 4;
}

.add-photos-text {
  grid-column: 1 / 4;
}

.custom-file-input {
  display: inline-block;
  position: relative;
  cursor: pointer;
  grid-column: 2;
  transition: transform 300ms, background 400ms;
}

.custom-file-input:hover {
  transform: scale(1.15);
  background: rgb(1, 155, 197);
}

.custom-file-label {
  background-color: #3498db;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.hidden-file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.unit-photos-selected-files {
  grid-column: 2;
}

/* Unit Building Features Section */
.listing-page-unit-features {
  border: 1px solid rgba(175, 175, 175, 0.5);
  border-radius: 1.5rem;
  padding: 2rem 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.features-selector {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem 1rem 2rem;
}

/* Unit Parking Section */
.listing-page-unit-parking {
  border: 1px solid rgba(175, 175, 175, 0.5);
  border-radius: 1.5rem;
  padding: 2rem 1rem 2rem 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 1rem;
  column-gap: 1rem;
  justify-items: center;
  align-items: center;
}

.unit-parking-title {
  grid-column: 1 / 4;
}

.unit-parking-input {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

/* Unit Contact Section */
.listing-page-unit-contact {
  border: 1px solid rgba(175, 175, 175, 0.5);
  border-radius: 1.5rem;
  padding: 2rem 1rem 2rem 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1rem;
  column-gap: 2rem;
  justify-items: center;
  align-items: center;
}

.unit-contact-title {
  grid-column: 1 / 3;
}

.unit-contact-input {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.unit-contact-input-box {
  width: auto !important;
}

.unit-details-text {
  color: #000;
  font-family: Lato;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.grey-text {
  color: #d9d9d9;
  text-align: center;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 5px;
}

.unit-details-input {
  width: 6rem;
  height: 2rem;
  flex-shrink: 0;
  border-radius: 1rem;
  border: 3px solid #89d2f3;
  padding: 0.5rem;
  font-size: 0.8rem;
}

.tag-bar {
  width: 1097.56px;
  height: 42.407px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 3px solid #89d2f3;
  background: #fff;
  margin-top: 10px;
}

.checkbox-container {
  display: flex;
  justify-content: flex-start; /* Align checkboxes to the left */
  gap: 10px; /* Adjust the gap between checkboxes */
}

/* @media only screen and (min-device-width: 1650px) {
  .sidebar-container {
    display: none;
  }
} */

@media screen and (max-width: 1000px) {
  .listing-page-body {
    width: 100%;
    float: none;
    padding: 2rem 1rem 2rem 1rem;
    gap: 1rem;
  }

  .sidebar-container {
    display: none;
  }

  .listing-page-content-title {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  /* Basic Details Section */

  .listing-page-basic-details {
    padding: 2rem 1rem 2rem 1rem;
  }

  .listing-page-basic-details-img {
    display: none;
  }

  .text-container {
    gap: 0.75rem;
    width: 100%;
  }

  .basic-details {
    font-size: 1.25rem;
    align-self: center;
  }

  .address {
    font-size: 0.85rem;
    margin-left: 0;
    margin-top: 0;
  }

  /* Unit Details Section */
  .listing-page-unit-details {
    border: 1px solid rgba(175, 175, 175, 0.5);
    border-radius: 1.5rem;
    padding: 2rem 1rem 2rem 1rem;
    display: flex;
    flex-direction: column;
  }

  .unit-details-selectors {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .unit-details {
    font-size: 1.25rem;
    margin-top: 0;
    grid-column: 1 / 3;
  }

  /* Unit Videos Section */
  .listing-page-unit-videos {
    border: 1px solid rgba(175, 175, 175, 0.5);
    border-radius: 1.5rem;
    padding: 2rem 1rem 2rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .upload-button {
    background: rgba(0, 191, 246, 1);
    border: none;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    margin-top: 0;
    width: 12rem;
    height: 2.5rem;
    border-radius: 1rem;
  }

  .add-attachment-text {
    margin-top: 0;
    font-size: 0.85rem;
  }

  .video-bar {
    width: 100%;
    height: 2.5rem;
    border-radius: 1rem;
    padding: 0.75rem;
    margin-top: 0;
  }

  /* Unit Photos Section */
  .listing-page-unit-photos {
    border: 1px solid rgba(175, 175, 175, 0.5);
    border-radius: 1.5rem;
    padding: 2rem 1rem 2rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  /* Unit Building Features Section */
  .listing-page-unit-features {
    border: 1px solid rgba(175, 175, 175, 0.5);
    border-radius: 1.5rem;
    padding: 2rem 1rem 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .features-selector {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    justify-content: center;
    align-items: center;
  }

  /* Unit Parking Section */
  .listing-page-unit-parking {
    border: 1px solid rgba(175, 175, 175, 0.5);
    border-radius: 1.5rem;
    padding: 2rem 1rem 2rem 1rem;
    display: flex;
    flex-direction: column;
  }

  /* Unit Contact Section */
  .listing-page-unit-contact {
    border: 1px solid rgba(175, 175, 175, 0.5);
    border-radius: 1.5rem;
    padding: 2rem 1rem 2rem 1rem;
    display: flex;
    flex-direction: column;
  }

  .unit-details-input {
    max-width: 100%;
    height: 30.492px;
    flex-shrink: 0;
    border-radius: 1rem;
    border: 2px solid #89d2f3;
    padding: 0.5rem;
  }
}
