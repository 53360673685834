.address-block-image {
  width: 15rem;
  height: 10rem;
  border-radius: 2rem;
}

#address-block-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#address-block-circle {
  width: 2rem;
  height: 2rem;
  background-color: rgba(210, 237, 249, 1);
  border-radius: 50%;
}

.address-block-apartment {
  margin-top: 0.35rem;
  font-family: Lato;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.address-block-details {
  font-family: Lato;
  font-size: 1rem;
  /* font-style: italic; */
  font-weight: 500;
  line-height: 0.9rem;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(157, 157, 157, 1);
}

@media screen and (max-width: 1060px) {
  .address-block-image {
    width: 12rem;
    height: 8rem;
    border-radius: 2rem;
  }

  .address-block-apartment {
    font-size: 0.9rem;
    width: 12rem;
  }

  .address-block-details {
    font-size: 0.75rem;
    width: 12rem;
  }
}

@media screen and (max-width: 865px) {
  .address-block-image {
    width: 8rem;
    height: 6rem;
    border-radius: 1rem;
  }

  .address-block-apartment {
    font-size: 0.75rem;
    width: 8rem;
  }

  .address-block-details {
    font-size: 0.6rem;
    width: 8rem;
  }
}

@media screen and (max-width: 600px) {
  .address-block-image {
    width: 12rem;
    height: 8rem;
    border-radius: 1.5rem;
  }

  .address-block-apartment {
    font-size: 1rem;
    width: 12rem;
  }

  .address-block-details {
    font-size: 0.85rem;
    width: 12rem;
  }
}

@media screen and (max-device-width: 600px) {
  .address-block-image {
    width: 20rem;
    height: 10rem;
    border-radius: 0.5rem;
  }

  .address-block-apartment {
    font-size: 1rem;
    width: 20rem;
  }

  .address-block-details {
    font-size: 0.85rem;
  }
}
