@import url("https://fonts.googleapis.com/css?family=Lato:400,700");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Lato;
  color: #000;
  text-align: left;
  font-family: Lato;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  font-size: 1rem;
}

img {
  max-width: 100%;
  height: auto;
}

.apartment-homepage-container {
  position: absolute;
  width: 100%;
  background: #ffffff;
  padding-top: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.apartment-homepage-main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3rem;
}

.apartment-homepage-main h1 {
  font-family: Lato;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(16, 15, 13, 1);
}

.apartment-homepage-main h2 {
  font-family: Lato;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(16, 15, 13, 1);
}

.details-container {
  padding-left: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.details-container ul {
  padding-left: 0.25rem;
  list-style-position: inside;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.details-container li {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.apartment-homepage-main text {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.apartment-homepage-main-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
}

.horizontal-line {
  margin: 1.5rem 0;
}

.info-block {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#location-address {
  font-family: Lato;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(157, 157, 157, 1);
}

/* START Review Section Classes */

#apartment-homepage-main-review {
  border: 1px solid rgba(0, 132, 189, 1);
  border-radius: 20px;
  padding: 2rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

#apartment-homepage-main-review h1 {
  font-family: Lato;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

#apartment-homepage-main-review h2 {
  font-family: Lato;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

#apartment-homepage-main-review text {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

#review-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#detailed-ratings {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 6rem;
  row-gap: 1rem;
}

#written-reviews {
  width: 85%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

#review-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  width: 100%;
}

#review-pagination p {
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 191, 246, 1);
}

/* .review-pagination-buttons {
  transition: all 1s ease-in-out;
}

.review-pagination-buttons :hover {
  transform: scale(5);
} */

.rating {
  width: 50px;
  height: 50px;
  background-color: rgba(3, 199, 255, 1);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.rating-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.7rem;
}

a {
  text-decoration: none;
}

#leave-review-button {
  width: 190px;
  height: 50px;
  border-radius: 15px;
  border: none;
  background: rgba(3, 199, 255, 1);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* END Review Section Classes */

.big-Header {
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  flex: 1;
}

.header-subtext {
  padding-top: 0.5rem;
  color: #9d9d9d;
  font-size: 1.5rem;
  flex: 1;
}

.content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.content-container ul {
  padding-left: 0.25rem;
  list-style-position: inside;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.apartment-map-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
}

/* START Image Group Classes */
.image-group {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40rem;
  align-items: flex-start; /* Align items to the start (left side) of the container */
  gap: 1rem;
  padding-bottom: 3rem;
}

.big-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
}

.individual-img-container {
  overflow: hidden;
  flex: 2;
  height: 100%;
}

.small-image-container {
  display: flex;
  flex: 1;
  flex-direction: column; /* Stack the small images and button vertically */
  align-items: flex-start; /* Align items to the start (left side) of the container */
  gap: 1rem;
  position: relative;
  height: 100%;
}

.individual-small-img-container {
  overflow: hidden;
  flex: 1;
  width: 100%;
  position: relative;
  justify-content: center; /* Center horizontally */
  align-items: center;
}

.show-all-img-container {
  overflow: hidden;
  flex: 1;
  width: 100%;
  position: relative;
  justify-content: center; /* Center horizontally */
  align-items: center;
  transition: filter 250ms;
}

.show-all-img-container:hover {
  filter: brightness(66%);
}

.small-image-container img {
  flex: 1;
  /* width:100%; */
  position: relative;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.filtered-image {
  mix-blend-mode: darken; /* Apply mix-blend-mode to Small 2 */
  filter: contrast(50%); /* Apply contrast filter to Small 2 */
  position: absolute;
  top: 0;
}

.show-all-button {
  color: #ffffff;
  font-size: 2rem;
  font-style: italic;
  font-weight: 700;
  border: none;
  border-radius: 0.5rem;
  background: none;
  cursor: pointer;
  text-align: center;
  position: absolute;
  width: 75%;
  height: 20%;
  box-sizing: content-box;
  left: 12.5%;
  top: 40%;
}

/* END Image Group Classes */

/* Media Queries for Responsiveness */

@media only screen and (max-device-width: 600px) {
  .apartment-homepage-container {
    padding: 1rem 2rem 1rem 2rem;
  }

  .image-group {
    height: auto;
    padding-bottom: 0;
  }

  .big-image {
    height: auto;
  }

  .small-image-container {
    display: none;
  }
}
